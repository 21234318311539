import React, { useEffect, useState, useCallback } from "react"
import { activationUser } from "../../components/Auth/Utils"
import withLocation from "../../components/shared/withLocation"
import Layout from "../../components/shared/layout"
import SEO from "../../components/SEO/SEOPage"
import ButtonLink from "../../components/shared/ButtonLink"
import styled from "@emotion/styled"
import { wrapper } from "../../utils/style"

const Wrapper = styled("div")`
  ${wrapper}
  margin: 30px auto;
  p {
    margin-bottom: 1rem;
  }
`

const ActivationUserTemplate = ({ search, navigate }) => {
  const homePageUrl =
    process.env.FRONTEND_PROTOCOL + "://" + process.env.FRONTEND_DOMAIN
  const [redirectToHome, setRedirectToHome] = useState(false)
  const [activationError, setActivationError] = useState(false)

  const activateUser = useCallback(() => {
    if (
      search &&
      search.action === "activation" &&
      search.email &&
      search.code
    ) {
      activationUser("", search, setRedirectToHome, setActivationError)
      setRedirectToHome(true)
    }
  }, [search])

  useEffect(() => {
    if (!redirectToHome) {
      activateUser()
    }
    const timer = setTimeout(() => {
      navigate(homePageUrl)
    }, 5000)
    return () => clearTimeout(timer)
    //setTimeout(navigate(homePageUrl), 3000);
    //navigate(homePageUrl);
  }, [activateUser, navigate, redirectToHome, homePageUrl])

  return (
    <Layout>
      <SEO
        title="Account Activation"
        description="Info on your HOLIDAYPALACE account activation"
        pathname={"/activation/"}
      />
      <Wrapper>
        {activationError ? (
          <>
            <h1>Activation Error:</h1>
            <p>
              ขออภัย ลิ้งก์เปิดใช้งานไม่ถูกต้อง หรือ
              มีการเปิดใช้งานไปแล้วก่อนหน้า
            </p>
            <ButtonLink
              to="/"
              text="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก"
              ariaText="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก"
            />
          </>
        ) : (
          <h1>Activating account...</h1>
        )}
      </Wrapper>
    </Layout>
  )
}

export default withLocation(ActivationUserTemplate)
